import React, { useEffect } from 'react'
import logo from '../../img/logo.png'
// import bgImage from '../../img/hero-bg.svg'
import bgImage from '../../img/bg.png'
import mapImage from '../../img/map.png'
// import heroImage from '../../img/hero-img.svg'
import heroImage from '../../img/hero.png'
import '../assets/components_styles.css';
// import about_img from '../../img/about/about-4/about-img.svg'
import about_img from '../../img/about/about-4/about-img.svg'
import brands_img from '../../img/clients/brands.png'
import { FaChevronRight, FaComment, FaEnvelope, FaEnvelopeOpenText, FaFacebookF, FaInstagram, FaLinkedin, FaMapMarked, FaPhone, FaTelegram, FaTwitter, FaUser } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Main() {
    useEffect(() => {
      AOS.init({ duration: 1000 }); 
    }, []);
    const img_bg = {
        backgroundImage: `url(${bgImage})`,
    }
    const containerStyle = {
      width: '100%',
      height: '200px'
    };
    
    const center = {
      lat: -1.288068,
      lng: 37.003792
    };
    const clipPathStyle = {
      clipPath: 'ellipse(100% 60% at 50% 40%)',
      backgroundImage: `url(${bgImage})`,
      backgroundSize: 'cover',
    };
    

  return (
    <div>
        
    <div className="preloader">
      <div className="loader">
        <div className="spinner">
          <div className="spinner-container">
            <div className="spinner-rotator">
              <div className="spinner-left">
                <div className="spinner-circle"></div>
              </div>
              <div className="spinner-right">
                <div className="spinner-circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section id="home" className="hero-section-wrapper-5">

      <header className="header header-6">
        <div className="navbar-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand" href="/">
                    <img src={logo} alt="logo" height={50} />
                  </a>
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent6" aria-controls="navbarSupportedContent6" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>
  
                  <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent6">
                    <ul id="nav6" className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <a className="page-scroll active" href="#home">Home</a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll" href="#services">Services</a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll" href="#about">About</a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll" href="#contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                  
                  {/* <div className="header-action d-flex">
                    <a href="#0"> <i className="lni lni-cart"></i> </a>
                    <a href="#0"> <i className="lni lni-alarm"></i> </a>
                  </div> */}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="hero-section hero-style-5 img-bg" style={clipPathStyle} >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="hero-content-wrapper">
                <h2 className="mb-30 wow fadeInUp" data-aos="fade-up" data-aos-delay="200">Apptech Technologies</h2>
                <p className="mb-30 wow fadeInUp" data-aos="fade-up" data-aos-delay="400">The most trusted professional service provider that delivers quality, reliable and innovative Information
                  Technology Infrastructure Solutions</p>
                <a href="#contact" className="button button-lg radius-50 wow fadeInUp" data-aos="fade-up" data-aos-delay="600">Hire Us <FaChevronRight /> </a>
              </div>
            </div>
            <div className="col-lg-6 align-self-end">
              <div className="hero-image wow fadeInUp" data-aos="fade-up" data-aos-delay="500">
              <img src={heroImage} alt="logo" />

              </div>
            </div>
          </div>
        </div>
      </div>

    </section>

    <section id="services" className="feature-section feature-style-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-5 col-xl-5 col-lg-7 col-md-8">
            <div className="section-title text-center mb-60">
              <h3 className="mb-15 wow fadeInUp" data-aos="fade-up" data-aos-delay="200">Our Services</h3>
              <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="200">Apptech Technologies provides services of market leader for all size of organizations. We focus in providing
                  products and solutions that fit current and future business needs. We render a range of products and services.</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="single-feature wow fadeInUp" data-aos="fade-up" data-aos-delay="200">
              <div className="content">
                <h5>IT Networks</h5>
                <p className='p_left'> 
                  <small>
                    <ul>
                      <li>Office Solutions</li>
                      <li>Enterprise Networks</li>
                      <li>Enterprise Software Applications</li>
                      <li>IP Telephony and Communication Systems</li>
                      <li>Compute, Storage & Virtualization Solutions</li>
                    </ul>
                  </small> 
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-feature wow fadeInUp" data-aos="fade-up" data-aos-delay="200">
              <div className="content">
                <h5>Networks and Cybersecurity</h5>
                <p className='p_left'> 
                  <small>
                    <ul>
                      <li>
                        Network Security
                        <ul className='m-1'>
                          <li>firewall implementation</li>
                          <li>intrusion detection/prevention</li>
                        </ul>
                        
                      </li>
                      <li>Systems Security</li>
                      <li>
                        Disaster Recovery Planning
                        <ul className='m-1'>
                          <li>vulnerability assessments</li>
                          <li>security audits</li>
                        </ul>
                      </li>
                    </ul>
                  </small> 
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-feature wow fadeInUp" data-aos="fade-up" data-aos-delay="200">
              <div className="content">
                <h5>Cloud Computing Services</h5>
                <p className='p_left'> 
                  <small>
                    <ul>
                      <li>Infrastructure as a Service (IaaS)</li>
                      <li>Platform as a Service (PaaS)</li>
                      <li>Software as a Service (SaaS)</li>
                      <li>serverless computing</li>
                      <p>This may involve cloud migration, deployment, and ongoing management.</p>
                    </ul>
                  </small> 
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-feature wow fadeInUp" data-aos="fade-up" data-aos-delay="200">
              <div className="content">
                <h5>Unified Communications</h5>
                <p className='p_left'> 
                  <small>
                    <ul>
                      <li>
                      Voice calling
                        <ul className='m-1'>
                          <li>voicemail transferring</li>
                          <li>call holding</li>
                          <li>three-way calling</li>
                        </ul>
                      </li>
                      <li>Messaging</li>
                      <li>Web conferencing</li>
                    </ul>
                  </small> 
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-feature wow fadeInUp" data-aos="fade-up" data-aos-delay="200">
              <div className="content">
                <h5>Enterprise Resource Planning (ERP) Implementation</h5>
                <p className='p_left'> 
                  <small>
                    <ul>
                      <li>Discovery & Planning</li>
                      <li>Design</li>
                      <li>Development</li>
                      <li>Testing</li>
                      <li>Deployment</li>
                      <li>Support & Updates</li>
                    </ul>
                  </small> 
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-feature wow fadeInUp" data-aos="fade-up" data-aos-delay="200">
              <div className="content">
                <h5>Computer Hardware and Software</h5>
                <p className='p_left'> 
                  <small>
                    
                    <ul>
                      <li>
                        Hardware
                        <ul className='m-1'>
                          <li>Laptops & Desktops</li>
                          <li>Printers & Scanners</li>
                          <li>Projectos & Cameras</li>
                        </ul>
                      </li>
                      <li>
                        Software
                        <ul className='m-1'>
                          <li>Static & Dynamic websites</li>
                          <li>Android & IOS applications</li>
                          <li>Windows & Linux applications</li>
                          <li>Machine Leaning Applications</li>
                        </ul>
                      </li>
                      
                    </ul>
                  </small> 
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-feature wow fadeInUp" data-aos="fade-up" data-aos-delay="200">
              <div className="content">
                <h5>Consulting and Advisory Services</h5>
                <p className='p_left'> 
                  <small>
                    Providing expert advice and guidance on
                    <ul>
                      <li>Technology strategies</li>
                      <li>Digital Transformation</li>
                      <li>IT Infrastructure Optimization</li>
                    </ul>
                  </small> 
                </p>
              </div>
            </div>
          </div>
          
          <div className="col-lg-4 col-md-6">
            <div className="single-feature wow fadeInUp" data-aos="fade-up" data-aos-delay="600">
              <div className="content">
                <h5>IT Training and Development</h5>
                <p className='p_left'> 
                  <small>
                    <ul>
                      <li>Product Training (SaaS, Adobe, Microsoft, Citrix, Red Hat, etc.)</li>
                      <li>Networking and Wireless</li>
                      <li>Data Storage</li>
                      <li>Databases/Database Management</li>
                    </ul>
                  </small> 
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-feature wow fadeInUp" data-aos="fade-up" data-aos-delay="600">
              <div className="content">
                <h5>Customer Support and Helpdesk Services</h5>
                <p className='p_left'> 
                  <small>
                    <ul>
                      <li>
                        Providing ongoing support, troubleshooting, and helpdesk services to address IT-related issues
                        and ensure smooth operation of systems.
                      </li>
                    </ul>
                  </small> 
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-feature wow fadeInUp" data-aos="fade-up" data-aos-delay="600">
              <div className="content">
                <h5> Telecommunications Services</h5>
                <p className='p_left'> 
                  <small>
                    Delivering communication solutions, including
                    <ul>
                      <li>voice services</li>
                      <li>data connectivity</li>
                      <li>telephony systems</li>
                    </ul>
                  </small> 
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-feature wow fadeInUp" data-aos="fade-up" data-aos-delay="400">
              <div className="content">
                <h5>Managed IT Services</h5>
                <p className='p_left'> 
                  <small>
                    Offering ongoing management and support for IT systems, including
                    <ul>
                      <li>network monitoring</li>
                      <li>cybersecurity</li>
                      <li>data backup</li>
                      <li>system maintenance</li>
                    </ul>
                  </small> 
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-feature wow fadeInUp" data-aos="fade-up" data-aos-delay="600">
              <div className="content">
                <h5>Project Management</h5>
                <p className='p_left'> 
                  <small>
                    <ul>
                      <li>
                        Providing project management services to oversee the planning, execution, and successful
                        delivery of technology projects, ensuring they align with business objectives.
                      </li>
                    </ul>
                  </small> 
                </p>
              </div>
            </div>
          </div>
        
        </div>

      </div>
    </section>

    <section id="service" className="about-section about-style-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 col-lg-6">
            <div className="about-content-wrapper">
              <div className="section-title mb-30">
                <h3 className="mb-25 wow fadeInUp" data-aos="fade-up" data-aos-delay="200">Need our Services?</h3>
                <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="300">Click to send us an email. We will reach out once approved</p>
              </div>
              <ul className='ul_style'>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="350">
                  <p className='b-round'>
                    ICT Maintenance and Support
                  </p>
                </li>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="400">
                  <p className='b-round'>
                    Structured Voice & Data Cabling
                  </p>
                </li>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="450">
                  <p className='b-round'>
                    Networking Devices Integration
                  </p>
                </li>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="450">
                  <p className='b-round'>
                    Firewall Security
                  </p>
                </li>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="450">
                  <p className='b-round'>
                    Data Center
                  </p>
                </li>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="450">
                  <p className='b-round'>
                    Fiber Optics
                  </p>
                </li>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="450">
                  <p className='b-round'>
                    0ffice 365
                  </p>
                </li>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="450">
                  <p className='b-round'>
                    Endpoint protection
                  </p>
                </li>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="450">
                  <p className='b-round'>
                    Server Maintenance
                  </p>
                </li>

                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="450">
                  <p className='b-round'>
                    Routers & Switches
                  </p>
                </li>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="450">
                  <p className='b-round'>
                    Wireless Network
                  </p>
                </li>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="450">
                  <p className='b-round'>
                  Security Surveillance
                  </p>
                </li>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="450">
                  <p className='b-round'>
                  LAN Optimization
                  </p>
                </li>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="450">
                  <p className='b-round'>
                  WAN Optimization
                  </p>
                </li>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="450">
                  <p className='b-round'>
                    Wireless Network
                  </p>
                </li>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="450">
                  <p className='b-round'>
                  SDWAN integration
                  </p>
                </li>
                <li className="wow fadeInUp" data-aos="fade-up" data-aos-delay="450">
                  <p className='b-round'>
                  CCTV installation
                  </p>
                </li>
              </ul>
              <a href='#' className='btn btn-sm btn-primary f-right'>More Services</a>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6">
            <div className="about-image text-lg-right wow fadeInUp" data-aos="fade-up" data-aos-delay="500">
              <img src={about_img} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="about" className="pricing-section pricing-style-4 bg-light">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 col-lg-6">
            <div className="section-title mb-60">
              <h3 className="mb-15 wow fadeInUp" ddata-aos="fade-up" data-aos-delay="200">About Us</h3>
              <p className="wow fadeInUp" data-aos="fade-up" data-aos-delay="400">
                Apptech Technologies is a fast-growing Information
                and Communication Technology (ICT) solutions
                provider. We design, install, integrate, and support
                ICT solutions and products. 
              </p>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6">
            <div className="pricing-active-wrapper wow fadeInUp" data-aos="fade-up" data-aos-delay="400">
              <div className="pricing-active">
                <div className="single-pricing-wrapper">
                  <div className="single-pricing">
                    <h3>More Info</h3>
                    <p>
                      <small>
                        We have qualified and enthusiastic staff who are
                        innovative to provide a solution that suites your
                        current and future needs. We believe in the art of
                        acquiring new skills to continuously evolve and
                        adapt to the fast-changing and advancing
                        technology.
                        We operate throughout Kenya with our offices in
                        Nairobi at Visions Plaza along Mombasa Road.
                        We emphasize on understanding the customer
                        needs, budget, and resources. This enables us to
                        design a cost-effective quality solution. We work to
                        facilitate business to achieve their goals using
                        desirable tools. 
                      </small>
                    </p>
                    {/* <a href="#0" className="button radius-30">Get Started</a> */}
                  </div>
                </div>
                <div className="single-pricing-wrapper">
                  <div className="single-pricing">
                    <h3>Mission & Vision </h3>
                    <h4>Mission</h4>
                    <p>
                      <small>
                        To create and provide quality, reliable, customer tailored and intelligent technology solutions to businesses.
                        Analyze customer requirements and use industry recommended standards to deliver quality solutions.
                      </small>
                    </p>

                    <br />
                    <h4>Vision</h4>
                    <p>
                      <small>
                        To be the most trusted professional service provider that delivers quality, reliable and innovative Information
                        Technology Infrastructure Solutions.
                      </small>
                    </p>
                    {/* <a href="#0" className="button radius-30">Get Started</a> */}
                  </div>
                </div>
                <div className="single-pricing-wrapper">
                  <div className="single-pricing">
                    {/* <h6>Pro Design</h6>
                    <h4>Design & Develop</h4> */}
                    <h3>Our Clients</h3>
                    <p>
                      <small>
                        <ul>
                          <li>Mobius Motors</li>
                          <li>Victorock Kenya Limited</li>
                          <li>Spire Bank LTD</li>
                          <li>Preferred Personnel Africa</li>
                          <li>SMEP Microfinance Bank</li>
                          <li>TriContinental Distrubution LTD</li>
                          <li>Rizenn Homes Ltd</li>
                          <li>International Christian Center Nairobi</li>
                          <li>The National Syndemic Diseases Control Council (NSDCC) formerly National Aids Control Council</li>
                          {/* <li></li> */}
                        </ul>
                      </small>
                    </p>
                    <a href="#0" className="btn btn-sm btn-outline-primary radius-30">Be part of the team</a>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="contact" className="contact-section contact-style-3">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-5 col-xl-5 col-lg-7 col-md-10">
            <div className="section-title text-center mb-50">
              <h3 className="mb-15">Get in touch</h3>
              <p>If you would like to find out more about the company, please contact us on</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <div className="contact-form-wrapper">
              <form action="#" method="POST">
                <div className="row">
                  <div className="col-md-6">
                    <div className="single-input">
                      <FaUser className="input-icon" />
                      <input type="text" id="name" name="name" className="form-input" placeholder="Name" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-input">
                      <FaEnvelope className="input-icon" />
                      <input type="email" id="email" name="email" className="form-input" placeholder="Email" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-input">
                      <FaPhone className="input-icon"/>
                      <input type="tel" id="number" name="number" className="form-input" placeholder="Number" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-input">
                      <FaEnvelopeOpenText className="input-icon" />
                      <input type="text" id="subject" name="subject" className="form-input" placeholder="Subject" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="single-input">
                      <FaComment className="input-icon"/>
                      <textarea name="message" id="message" className="form-input" placeholder="Message" rows="6"></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-button text-center">
                      <button type="submit" className="button"> <FaTelegram className='m-2'/>  Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

          </div>

          <div className="col-lg-4">
            <div className="left-wrapper">
              <div className="row">
                <div className="col-lg-12 col-md-6">
                  <div className="single-item">
                    <div className="icon">
                      <FaPhone />
                    </div>
                    <div className="text">
                      <p><a href='tel:+254713690649'>+254 713690649</a></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-6">
                  <div className="single-item">
                    <div className="icon">
                      <FaEnvelope />
                    </div>
                    <div className="text">
                      <p><a href='mailto:support@apptech.co.ke'>support@apptech.co.ke</a></p>
                      <p><a href='mailto:tech@apptech.co.ke'>tech@apptech.co.ke</a></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-6">
                  <div className="single-item">
                    <div className="icon">
                      <FaMapMarked />
                    </div>
                    <div className="text">
                      <p>
                        P.O Box 3150-00506
                        <br />
                        Nairobi
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="clients-logo-section pt-100 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="client-logo wow fadeInUp" data-aos="fade-up" data-aos-delay="200">
              <img src={brands_img} alt="" className="w-100"/>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer className="footer footer-style-4">
      <div className="container">
        <div className="widget-wrapper">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="footer-widget wow fadeInUp" data-aos="fade-up" data-aos-delay="200">
                <div className="logo">
                  <a href="#0"> <img src={logo} alt=""/> </a>
                </div>
                <p className="desc">
                  Empowering Your Vision with Innovative Solutions
                </p>
                <ul className="socials">
                  <li> <a href="#0"> <FaFacebookF /> </a> </li>
                  <li> <a href="#0"> <FaTwitter /> </a> </li>
                  <li> <a href="#0"> <FaInstagram /> </a> </li>
                  <li> <a href="#0"> <FaLinkedin /> </a> </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 offset-xl-1 col-lg-2 col-md-6 col-sm-6">
              <div className="footer-widget wow fadeInUp" data-aos="fade-up" data-aos-delay="300">
                <h6>Quick Link</h6>
                <ul className="links s_none">
                  <li> <a href="#services">Service</a> </li>
                  <li> <a href="#contact">Contact</a> </li>
                  <li> <a href="#0">Privacy Policy</a> </li>
                  <li> <a href="#0">FAQ</a> </li>
                  <li> <a href="#0">Career</a> </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="footer-widget wow fadeInUp" data-aos="fade-up" data-aos-delay="400">
                <h6>Working Hours</h6>
                <ul className="links">
                <li> <a href="#0">Monday 8am - 6pm</a> </li>
                <li> <a href="#0">Tuesday 8am - 6pm</a> </li>
                <li> <a href="#0">Wednesday 8am - 6pm</a> </li>
                <li> <a href="#0">Thursday 8am - 6pm</a> </li>
                <li> <a href="#0">Friday 8am - 6pm</a> </li>
                <li> <a href="#0">Saturday 8am - 6pm</a> </li>
                </ul>
              </div>
            </div>
            
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="footer-widget wow fadeInUp" data-aos="fade-up" data-aos-delay="400">
                <h6>Our Location</h6>
                <img src={mapImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <a href="#" className="scroll-top"> <i className="lni lni-chevron-up"></i> </a>
		
    </div>
  )
}

export default Main
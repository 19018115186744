import './App.css';
import Main from './components/MainComponent/Main';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import './components/assets/main'
// import MyComponent from './components/assets/main';

// colour = #1E7DA8
function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
